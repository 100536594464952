
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import Owner from '../build/ownerDialog.vue'
import Device from '../build/deviceDialog.vue'

@Component({
  components: { Owner, Device }
})
@KeepAlive
export default class Department extends Vue {
  private tableData = []
  private roomId = '' // 添加业主使用

  get userName () {
    let name = ''
    if (this.$store.state.userInfo && this.$store.state.userInfo.userName) {
      name = this.$store.state.userInfo.userName
    } else if (window.sessionStorage.getItem('userInfo')) {
      name = JSON.parse(window.sessionStorage.getItem('userInfo') as any).userName
    }
    return name
  }

  created () {
    this.getBuildList()
    // console.log('测试')
  }

  getBuildList () {
    this.$axios.get(this.$apis.smartHouse.selectSqIrrigationValveBuildingByList).then((res) => {
      this.tableData = res || []
    })
  }

  onDetail (buildingId: string) {
    this.$router.push({
      path: '/smartHouse/realtime/detail',
      query: {
        buildingId
      }
    })
  }
}
